import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Button } from "reactstrap";
import LetsTalkModal from "./Modals/LetsTalkModal";

const IndividualPlanCard = ({ index, data }) => {
    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);
    return (
        <div
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
            data-aos-delay={`${index * 200}`}
            className={`wrapper h-100 border p-sm-5 p-3 rounded plan-card shadow-sm text-center`}
            itemScope
            itemType="http://schema.org/Product"
        >
            <div className="mb-5 text-sm-center">
                <div >
                    <picture>
                        <source type="image/webp" srcset={data?.image} />
                        <img src={data?.image} alt="cardImg" itemProp="image" />
                    </picture>
                </div>
                <div className="my-3">
                    <h2 className="plans-heading" itemProp="name">{data.name}</h2>
                    {data.details && <p itemProp="description">{data.details}</p>}
                </div>{" "}
                <div className="my-4">
                    <Button
                        className="bg-site-success border border-0"
                        onClick={() => talkToggle()}
                    >
                        LET'S TALK!
                    </Button>
                </div>
                {data.services.map((items, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex gap-3 align-items-center mt-1 pl-0"
                            itemScope
                            itemType="http://schema.org/Service"
                        >
                            <span className="mr-2">
                                {" "}
                                <FaCheck className="text-site-success" />
                            </span>

                            <h6 className="mb-0" itemProp="name">{items}</h6>
                        </div>
                    );
                })}
            </div>
            <LetsTalkModal planName={data.name} talkOpen={talkOpen} talkToggle={talkToggle} />
        </div>
    );
};

export default IndividualPlanCard;
