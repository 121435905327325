import React from "react";
import { FaCheck } from "react-icons/fa";
import { Button } from "reactstrap";
import LetsTalkModal from "./Modals/LetsTalkModal";
import { useState } from "react";

const PlanCards = ({ showPrices, index, data }) => {
    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);
    return (
        <div
            data-aos="zoom-out-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay={`${index * 500}`}
            className={`wrapper h-100 border p-5 rounded plan-card shadow-sm`}
            itemScope
            itemType="http://schema.org/Product"
        >
            <>
                <div className="ribbon-wrapper">
                    <div className="ribbon-top"></div>
                </div>
                <div className="ribbon-wrapper-bottom">
                    <div className="ribbon-bottom"></div>
                </div>
            </>
            <div className="mb-5">
                <div className="text-center my-3">
                    <h2 className="basic-plans-heading" itemProp="name">{data.name}</h2>
                </div>
                    <>
                        <div className="d-flex justify-content-center align-items-end">
                            <span className="mb-4 fs--30 fw--500 h-100">$</span>
                            <span className=" fw--600 fs--50 text-center mb-0" itemProp="price">
                                {data.price}
                            </span>
                            <span className="mb-2 fw--500">/month</span>
                        </div>
                    </>
                <div className="text-center my-4">
                    <Button onClick={() => { talkToggle() }} className="bg-site-success border border-0">
                        CONNECT WITH US
                    </Button>
                </div>
                {data.services.map((items, index) => {
                    return (
                        <div
                            key={index}
                            className="d-flex gap-3 align-items-center mt-1 pl-0"
                            itemScope
                            itemType="http://schema.org/Service"
                        >
                            <span className="mr-2">
                                {" "}
                                <FaCheck className="text-site-success" />
                            </span>

                            <h6 className="mb-0" itemProp="name">{items}</h6>
                        </div>
                    );
                })}
            </div>
            <LetsTalkModal planName={data.name} talkOpen={talkOpen} talkToggle={talkToggle} />
        </div>
    );
};
export default PlanCards;
