import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { getQuestions } from "../store/actions/getDataActions";
import { TOGGLE_FAQ_COLLAPSE } from "../store/types";

const Questions = () => {

    const { faqs } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getQuestions())
    }, []);

    const toggleFaqCollapse = (id) => {
        dispatch({
            type: TOGGLE_FAQ_COLLAPSE,
            payload: id,
        });
    };

    return (
        <div className="questions-section p-3 py-lg-5">
            <Container>
                <Row>
                    <Col
                        lg="6"
                        className="d-flex justify-content-lg-center align-items-lg-center"
                    >
                        <div className="question-left">
                            <h2>FAQ’S</h2>
                            <h3>
                                Got Questions? Here's A Few{" "}
                                <span>Answers...</span>{" "}
                            </h3>
                            <p>
                                If there's anything else you'd like to know,
                                just ask!
                            </p>
                            <Button color="site-success">Contact Us</Button>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div
                            className="accordion accordion-flush mt-3 mt-lg-0 overflow-hidden"
                            id="accordionFlushExample"
                        >
                            {faqs.map((item, index) => (
                                <div
                                    key={item.id}
                                    data-aos="fade-left"
                                    data-aos-delay={`${index * 300}`}
                                    data-aos-duration="300"
                                    className="accordion-item position-relative"
                                >
                                    <h2 className="accordion-header p-2">
                                        <button
                                            className={`accordion-button ${item.toggle
                                                ? ""
                                                : "collapsed"
                                                }`}
                                            type="button"
                                            onClick={() => toggleFaqCollapse(item.id)}

                                            aria-expanded={!item.toggle}
                                            aria-controls={`flush-collapse-${item.id}`}
                                        >
                                            {item.question}
                                        </button>
                                    </h2>
                                    <div
                                        id={`flush-collapse-${item.id}`}
                                        className={`accordion-collapse collapse ${item.toggle
                                            ? ""
                                            : "show p-4 bg-white"
                                            }`}
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div
                                            className="accordion-body"
                                            dangerouslySetInnerHTML={{
                                                __html: item.answer,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Questions;