import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import IndividualPlanCard from "./IndividualPlanCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllIndividualPlans } from "../store/actions/getDataActions";

const PlanData = () => {

    const { individualPlans } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllIndividualPlans())
    }, []);

    return (
        <div className="plans-section p-3 py-lg-0">
            <Container className="p-0">
                <h1>
                    <span>Individual </span>Plans
                </h1>
                <Row className="pt-0 m-0">
                    {individualPlans.map((data, index) => {
                        return (
                            <Col md="6" lg="4" key={data.id} className="mb-5">
                                <IndividualPlanCard index={index} data={data} />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default PlanData;
