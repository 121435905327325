import { ADD_PLANSDATA } from "../types";

const initialState = {
    plansData: [],
};

const addPlansDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ADD_PLANSDATA:
            return {
                ...state,
                plansData: payload,
            };
        default:
            return state;
    }
};

export default addPlansDataReducer;