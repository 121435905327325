import firebase from '../../config/firebase';
import { ADD_PLANSDATA } from '../types';
const collection = firebase.firestore().collection('plansData');

export const addPlansData = (formData, phoneNumber) => async (dispatch) => {
    try {

        formData.phoneNumber = Number(phoneNumber) ;
        formData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
        await collection.add(formData);

         
    } catch (error) {
        console.error('Error adding individual plan: ', error);
    }
};