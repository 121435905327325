import React, { useState } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ServicesList from "./ServicesList";
import LetsTalkModal from "./Modals/LetsTalkModal";

const Services = () => {
    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);

    return (
        <div className="services  py-5 bg-site-lite-grey" id="/home/skills">
            <Container>
                <h1>
                    <span>Our</span> resources pretty good at this stuff
                </h1>
                <br/>
                <Row>
                    <Col>
                        <ServicesList />
                    </Col>

                </Row>
                <Row className="text-center ">
                    <Col>
                        <Button
                            className="bg-site-success border border-0"
                            onClick={() => talkToggle()}
                        >
                            LET'S TALK!
                        </Button>
                    </Col>
                </Row>
            </Container>
            <LetsTalkModal talkOpen={talkOpen} talkToggle={talkToggle} />
        </div>
    );
};

export default Services;
