import React, { useEffect } from "react";
import Questions from "../components/Questions";

const FAQ = () => {
    useEffect(() => {
        const element = document.getElementById("/faq");

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }, []);

    return (
        <div id="/faq">
            <Questions />
        </div>
    );
};

export default FAQ;