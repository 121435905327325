// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import addPlansDataReducer from "./addPlansDataReducer";
import getDataReducer from "./getDataReducer";

export const rootReducer = combineReducers({
  authUser: authUserReducer,
  plansData: addPlansDataReducer,
  getData: getDataReducer,
});

export default rootReducer;
