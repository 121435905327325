import { SET_INDIVIDUALPLANS, SET_TEAMPLANS, SET_TESTIMONIALS, SET_HOW_WE_WORK, SET_QUESTIONS, SET_SERVICES_LIST, TOGGLE_FAQ_COLLAPSE, SET_OFFERS } from "../types";

const initialState = {
    individualPlans: [],
    teamPlans: [],
    testimonials: [],
    HowWeWork: [],
    ServicesList: [],
    faqs: [],
    offers: []
};

const getDataReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case SET_TEAMPLANS:
            return {
                ...state,
                teamPlans: payload,
            };

        case SET_INDIVIDUALPLANS:
            return {
                ...state,
                individualPlans: payload,
            };

        case SET_TESTIMONIALS:
            return {
                ...state,
                testimonials: payload,
            };

        case SET_HOW_WE_WORK:
            return {
                ...state,
                HowWeWork: payload,
            };

        case SET_SERVICES_LIST:
            return {
                ...state,
                ServicesList: payload,
            };

        case SET_OFFERS:
            return {
                ...state,
                offers: payload,
            };

        case SET_QUESTIONS:
            return {
                ...state,
                faqs: payload,
            };

        case TOGGLE_FAQ_COLLAPSE:
            const updatedFaqs = state.faqs.map((item) =>
                item.id == payload
                    ? { ...item, toggle: !item.toggle }
                    : item
            );
            return {
                ...state,
                faqs: updatedFaqs,

            };

        default:
            return { ...state };

    }
};
export default getDataReducer;