import { useEffect } from "react";
import { getServicesList } from "../store/actions/getDataActions";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

const ServicesList = () => {

    const { ServicesList } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getServicesList())
    }, []);

    return (
        <Row>
            {ServicesList?.map((item, index) => {
                return (
                    <Col lg='3' sm='12' md='6' key={index} itemScope itemType="https://schema.org/Service">

                        <div className="d-flex" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                            <div className="mr-1 heading">
                                <svg
                                    itemProp="image"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fal"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 590 512"
                                >
                                    <path
                                        fill="currentColor"
                                        d={item.icon}
                                    ></path>
                                </svg>
                            </div>

                            <div className="d-flex flex-column">
                                <h2 className="heading" itemProp="name">{item.title}</h2>

                                <ul className="list-unstyled overflow-hidden" itemProp="offers" itemScope itemType="https://schema.org/Service">
                                    {item.list?.map((subItem, subIndex) => (
                                        <li
                                            key={subIndex}
                                            data-aos="fade-left"
                                            data-aos-delay={`${subIndex * 200}`}
                                            data-aos-duration="300"
                                        >
                                            <h3 className="text-dark" itemProp="name">
                                                {subItem}
                                            </h3>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
}

export default ServicesList;
