import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    Form,
    Label,
    Modal,
    ModalBody,
    Row,
} from "reactstrap";
import Logo from "../../assets/img/logo.webp";
import { LuClock11 } from "react-icons/lu";
import { FaLongArrowAltRight, FaArrowLeft } from "react-icons/fa";
import CalendlyComponent from "../CalendlyComponent ";
import PhoneInput from "react-phone-number-input";
import { addPlansData } from "../../store/actions/addPlansDataAction";
import { useDispatch } from "react-redux";
// import "../../assets/css/style.css";

const LetsTalkModal = ({ talkOpen, talkToggle, planName }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState();

    const dispatch = useDispatch()

    const [formData, setFormData] = useState({
        email: "",
        projectHighlights: "",
        firstName: "",
        lastName: "",
        company: "",
        // phone: "",
    });
    const [errors, setErrors] = useState({
        email: "",
        projectHighlights: "",
        firstName: "",
        lastName: "",
        company: "",
        // phone: "",
    });
    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevError) => ({
            ...prevError,
            [name]: false,
        }));
    };

    useEffect(() => {
        if (planName) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                projectHighlights: `I have chosen ${planName} plan.`,
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                projectHighlights: "",
            }));
        }
    }, [planName]);

    const validateEmail = (email) => {
        // Regular expression for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleNextClick = (event) => {
        event.preventDefault();
        // Validate the email

        if (stepNumber == 1) {
            const emailInput = formData.email;
            const email = emailInput.trim();

            if (email === "") {
                setErrors((pre) => ({ ...pre, email: "Email is required." }));
                return;
            } else if (!validateEmail(email)) {
                setErrors((pre) => ({
                    ...pre,
                    email: "Invalid email format.",
                }));
                return;
            }

            setStepNumber(2);
        } else if (stepNumber == 2) {
            setStepNumber(3);
        } else if (stepNumber == 3) {
            const firstName = formData.firstName.trim();
            const lastName = formData.lastName.trim();
            const company = formData.company.trim();
            // const phone = formData.phone.trim();

            // Validation using regex
            const nameRegex = /^[a-zA-Z]+$/;
            const phoneRegex = /^[0-9]+$/;

            let valid = true;
            let errorsObj = {};

            if (!firstName || !nameRegex.test(firstName)) {
                valid = false;
                errorsObj = { ...errorsObj, firstName: "Invalid first name." };
            }

            if (!lastName || !nameRegex.test(lastName)) {
                valid = false;
                errorsObj = { ...errorsObj, lastName: "Invalid last name." };
            }

            if (!company) {
                valid = false;
                errorsObj = { ...errorsObj, company: "Company is required." };
            }

            if (!phoneNumber && !phoneRegex.test(phoneNumber)) {
                valid = false;
                errorsObj = { ...errorsObj, phone: "Invalid phone number." };
            }

            if (valid) {
                console.log("payload  :", formData);
                // <!-- Calendly inline widget begin -->
                setStepNumber(4);
                dispatch(addPlansData(formData, phoneNumber))
                // <!-- Calendly inline widget end -->
            } else {
                setErrors((pre) => ({ ...pre, ...errorsObj }));
            }
        }
    };

    const ModalHeader = () => (
        <div className="d-flex align-items-center px-3 pt-2">
            <picture>
                <source type="image/webp" srcset={Logo} />
                <img src={Logo} alt="logo" />
            </picture>
            <h5 className="m-0 pl-1">NextPak</h5>
        </div>
    );
    const initialLayout = () => {
        return (
            <>
                {planName && <h2 className="fw--700">{planName}</h2>}
                <span className="fs--56 gradient-text">NextPak</span>
                <p className="mt-4 fs--20">
                    We build great technology products. <br></br>Rejoice. Help
                    is here.
                </p>
                <Button
                    className="bg-site-success border border-none w-25"
                    onClick={() => setStepNumber(1)}
                >
                    Start
                </Button>
                <div className="d-flex align-items-center mt-3">
                    {" "}
                    <LuClock11 className="mb-1" />
                    <p className=" ml-2 mb-0">Take 15 sec</p>
                </div>
            </>
        );
    };
    const renderEmailStep = () => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 text-primary">{stepNumber}</h5>
                    <FaLongArrowAltRight className="text-primary" />
                    <h5 className="mb-0 ml-2">What's your email address?*</h5>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="example@gmail.com"
                        value={formData?.email}
                        onChange={handleChange}
                    />
                    <div className="underline"></div>
                </div>
                {errors?.email && (
                    <div className="error-message">{errors?.email}</div>
                )}
            </>
        );
    };
    const renderProjectHighlightsStep = () => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 text-primary">{stepNumber}</h5>
                    <FaLongArrowAltRight className="text-primary" />
                    <h5 className="mb-0 ml-2">
                        Could you share a few project highlights to optimize our
                        upcoming conversation?
                    </h5>
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        id="projectHighlights"
                        name="projectHighlights"
                        placeholder="Type your answer"
                        value={formData?.projectHighlights}
                        onChange={handleChange}
                    />

                    <div className="underline"></div>
                </div>
            </>
        );
    };
    const renderContactInfoStep = () => {
        return (
            <>
                <div className="d-flex align-items-center">
                    <h5 className="mb-0 text-primary">{stepNumber}</h5>
                    <FaLongArrowAltRight className="text-primary" />
                    <h5 className="mb-0 ml-2">Contact Info*</h5>
                </div>
                <Container className="mt-5">
                    <Row>
                        <Col>
                            {" "}
                            <FormGroup>
                                <Label for="firstName" className="mb-0">
                                    First Name*
                                </Label>
                                <div class="input-container">
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Jane"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                    />
                                    <div class="underline"></div>
                                </div>
                                {errors?.firstName && (
                                    <div className="error-message">
                                        {errors?.firstName}
                                    </div>
                                )}
                            </FormGroup>{" "}
                        </Col>
                        <Col>
                            {" "}
                            <FormGroup>
                                <Label for="firstName" className="mb-0">
                                    Last Name*
                                </Label>
                                <div class="input-container">
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="smith"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                    />
                                    <div class="underline"></div>
                                </div>
                                {errors?.lastName && (
                                    <div className="error-message">
                                        {errors?.lastName}
                                    </div>
                                )}
                            </FormGroup>{" "}
                        </Col>
                    </Row>{" "}
                    <Row className="mt-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label for="firstName" className="mb-0">
                                    Company*
                                </Label>
                                <div class="input-container">
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        placeholder="ACME"
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                    <div class="underline"></div>
                                </div>
                                {errors?.company && (
                                    <div className="error-message">
                                        {errors?.company}
                                    </div>
                                )}
                            </FormGroup>{" "}
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="firstName" className="mb-0">
                                    Phone
                                </Label>
                                <div class="mt-1">
                                    <PhoneInput
                                        placeholder="Enter phone number"
                                        value={phoneNumber}
                                        onChange={setPhoneNumber}
                                    />
                                </div>
                                {errors?.phone && (
                                    <div className="error-message mt-2">
                                        {errors?.phone}
                                    </div>
                                )}
                            </FormGroup>{" "}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
    const renderCurrentStep = () => {
        switch (stepNumber) {
            case 1:
                return renderEmailStep();
            case 2:
                return renderProjectHighlightsStep();
            case 3:
                return renderContactInfoStep();

            default:
                return;
        }
    };

    useEffect(() => {
        setErrors((prevError) => ({
            ...prevError,
            ["phone"]: false,
        }));
    }, [phoneNumber]);
    return (
        <Modal isOpen={talkOpen} toggle={talkToggle} centered size="lg">
            <ModalHeader />
            <ModalBody
                className=" modal-body"
            >
                {stepNumber == 4 ? (
                    <CalendlyComponent />
                ) : (
                    <div className="fw--400 lets-talk-Modal">
                        <Form>
                            {stepNumber != 0 ? (
                                <>
                                    <FaArrowLeft
                                        className="cursor-pointer mb-4"
                                        onClick={() =>
                                            setStepNumber((pre) => pre - 1)
                                        }
                                    />

                                    {renderCurrentStep()}

                                    <div
                                        className={`${stepNumber === 3
                                            ? "d-flex justify-content-center"
                                            : ""
                                            }`}
                                    >
                                        <Button
                                            type="button"
                                            className="bg-site-success border border-none w-25 mt-2"
                                            onClick={handleNextClick}
                                        >
                                            {stepNumber < 3 ? "Next" : "Submit"}
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                initialLayout()
                            )}
                        </Form>
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default LetsTalkModal;
