import React from "react";
import PlanCards from "./PlanCards";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeamPlans } from "../store/actions/getDataActions";
import { useEffect } from "react";

const TeamPlans = () => {

    const { teamPlans } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllTeamPlans())
    }, []);

    return (
        <div className="basic-plans-section p-3 pt-5 py-lg-0}">
            <Container className="p-0">
                <h1>
                    <span>Team </span>plans
                </h1>
                <Row className="pt-5 m-0">
                    {teamPlans.map((data, index) => {
                        return (
                            <Col md="6" lg="4" key={data.id} className="mb-5">
                                <PlanCards index={index} showPrices={true} data={data} />
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default TeamPlans;