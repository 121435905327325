import React, { useState } from "react";
import Logo from "../assets/img/logo.webp";

import { IoIosArrowForward } from "react-icons/io";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { navLinks } from "../utils/Content";
import LetsTalkModal from "./Modals/LetsTalkModal";

const Navbar = () => {
    const [isNavOpen, setNavOpen] = useState(false);
    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);
    const history = useHistory();
    const location = useLocation();

    const toggleNavbar = () => {
        setNavOpen(!isNavOpen);
    };

    return (
        <div className="navbar-section shadow-sm" itemScope itemType="http://schema.org/SiteNavigationElement">
            <Container className="navbar-container ">
                <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-between w-100 px-0">
                    <div
                        className="navbar-brand  cursor-pointer"
                        onClick={() => history.push("/")}
                        itemProp="logo"
                    >
                        <picture>
                            <source type="image/webp" srcset={Logo} />
                            <img src={Logo} alt="logo" itemProp="image" />
                        </picture>
                        <h5 className="m-0 pl-1" itemProp="name">NextPak</h5>
                    </div>

                    {/* Mobile */}
                    <div id="navbarNav" className="d-lg-none d-block ml-auto">
                        {" "}
                        <button
                            className={`navbar-toggler menu__icon custom-menu float-right ${isNavOpen ? "clicked" : ""
                                }`}
                            onClick={toggleNavbar}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                        {isNavOpen && (
                            <div
                                className={`collapse navbar-collapse position-relative  ${isNavOpen ? "show" : ""
                                    }`}
                            >
                                <ul className="navbar-nav   pl-2 pr-5 pt-5">
                                    {navLinks.map((ele, index) => {
                                        return (
                                            <li
                                                key={index}
                                                className="nav-item active mt-2 d-flex justify-content-center align-items-center"
                                            >
                                                <span
                                                    className="nav-link fs--16"
                                                    href={ele?.link}
                                                    itemProp="url"
                                                    onClick={() => {
                                                        history.push(ele?.link);
                                                        setNavOpen(!isNavOpen)
                                                    }
                                                    }
                                                >
                                                    {ele?.title}
                                                </span>
                                                <IoIosArrowForward className="fs--30" />
                                            </li>
                                        );
                                    })}

                                    <li className="nav-item active">
                                        <Link
                                            className="nav-link"
                                            to="/#services"
                                            itemProp="url"
                                        >
                                            Services
                                        </Link>
                                    </li>
                                    <li className="nav-item active mt-2">
                                        <a
                                            className="btn bg-site-success headerButtonSchedule"
                                            onClick={() => talkToggle()}
                                        >
                                            Let's Talk
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    {/* Web */}
                    <div
                        className="d-flex justify-content-around d-lg-block d-none"
                        id="navbarNav"
                    >
                        <ul className="navbar-nav d-lg-flex d-none ">
                            {navLinks.map((ele, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`nav-item  d-flex flex-column  align-items-center ${location.pathname === ele.link
                                            ? "text-site-success"
                                            : ""
                                            }`}
                                    >
                                        <a
                                            className={`nav-link px-3 ${location.pathname === ele.link
                                                ? "text-site-success"
                                                : ""
                                                }`}
                                            // href={ele?.link}
                                            onClick={() =>
                                                history.push(ele?.link)
                                            }
                                        >
                                            {ele?.title}
                                        </a>
                                        {location.pathname === ele.link && (
                                            <div
                                                className="bg-site-success rounded-pill w-50 "
                                            ></div>
                                        )}
                                    </li>
                                );
                            })}

                            <li className="nav-item active  ml-2">
                                <a
                                    className="btn bg-site-success headerButtonSchedule"
                                    // href="#"
                                    onClick={() => talkToggle()}
                                >
                                    Let's Talk
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </Container>
            <LetsTalkModal talkOpen={talkOpen} talkToggle={talkToggle} />
        </div>
    );
};

export default Navbar;
