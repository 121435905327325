export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

export const AUTH_SET_LOADING = "AUTH_SET_LOADING";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";

export const SET_TESTIMONIALS= "SET_TESTIMONIALS";
export const SET_TEAMPLANS= "SET_TEAMPLANS";
export const SET_INDIVIDUALPLANS= "SET_INDIVIDUALPLANS";
export const SET_HOW_WE_WORK= "SET_HOW_WE_WORK";
export const SET_SERVICES_LIST= "SET_SERVICES_LIST";
export const SET_QUESTIONS= "SET_QUESTIONS";
export const TOGGLE_FAQ_COLLAPSE= "TOGGLE_FAQ_COLLAPSE";
export const SET_OFFERS= "SET_OFFERS";

export const ADD_PLANSDATA = 'ADD_PLANSDATA'