import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HowWeWork from "../components/HowWeWork";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import WhatWeOffer from "../components/WhatWeOffer";

const Home = (props) => {
    const location = useLocation();
 
    useEffect(() => {
        const element = document.getElementById(location.pathname);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }, [location.pathname]);

    return (
        <>
            <WhatWeOffer />
            <Testimonials />
            <HowWeWork />
            <Services />
            <br/><br/>
        </>
    );
};

export default Home;
