// CalendlyComponent.js

import React, { useEffect } from "react";
import { Container } from "reactstrap";

const CalendlyComponent = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <Container className="text-center h-100 calendly">
            <h2>Thanks for reaching out! </h2>
            <p>Let's find a time to connect!</p>
            <div
                className="calendly-inline-widget "
                data-url="https://calendly.com/sales-np/30min?background_color=f5f2f2&text_color=130101&primary_color=2e8b57&month=2023-12"
            />
        </Container>
    );
};

export default CalendlyComponent;
