import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import store from "./store/store";
import { useEffect } from "react";
import { GA4React } from "ga-4-react";

// ..
AOS.init();

function App() {

    useEffect(() => {
        const ga4react = new GA4React("G-60H1BG4TRM");
        ga4react.initialize().then((ga) => {
            // Track pageview on app load
            ga.pageview(window.location.pathname + window.location.search);
        });
        return () => {
            ga4react.then((ga) => {
                ga.pageview(window.location.pathname + window.location.search);
            });
        };
    }, []);

    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {routes.map((route) => {
                            switch (route.layout) {
                                case "main":
                                    return (
                                        <Route key={`main-${route.id}`} exact path={route.path}>
                                            <Main>
                                                <route.component />
                                            </Main>
                                        </Route>
                                    );
                                case "auth":
                                    return (
                                        <Route key={`auth-${route.id}`} exact path={route.path}>
                                            <Auth>
                                                <route.component />
                                            </Auth>
                                        </Route>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </Switch>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;