import { useEffect } from "react";
import { Container } from "reactstrap";
import TestimonialsSlide from "./TestimonialsSlide";
import { useDispatch, useSelector } from "react-redux";
import { getAllTestimonials } from "../store/actions/getDataActions";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { IoIosArrowDroprightCircle } from "react-icons/io";

const Testimonials = () => {

    const { testimonials } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllTestimonials())
    }, []);

    const slideLeft = () => {
        const slide = document.getElementById('slider');
        const scrollAmount = slide.clientWidth;
        slide.scrollLeft -= scrollAmount;
        if (slide.scrollLeft <= 0) {
            slide.scrollLeft = slide.scrollWidth - scrollAmount;
        }
    };
    
    const slideRight = () => {
        const slide = document.getElementById('slider');
        const scrollAmount = slide.clientWidth;
        slide.scrollLeft += scrollAmount;
        if (slide.scrollLeft >= slide.scrollWidth - slide.offsetWidth) {
            slide.scrollLeft = 0;
        }
    };

    return (
        <div className="testimonials pt-3 pb-5 py-md-5" id="/home/testimonials">
            <Container>
                <h1
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                >
                    <span>Hear</span> why our client think we're cool
                </h1>

                <div className="d-flex align-items-center">

                    <div className="slider-btn">
                        <IoIosArrowDropleftCircle onClick={slideLeft} />
                    </div>

                    <div className="slider" id='slider' data-aos="zoom-in-right" data-aos-duration="2000">
                        {testimonials.map((data) => (
                            <TestimonialsSlide key={data.id} data={data} />
                        ))}
                    </div>

                    <div className="slider-btn">
                        <IoIosArrowDroprightCircle className="slider-btn" onClick={slideRight} />
                    </div>

                </div>
            </Container>
        </div>
    );
};

export default Testimonials;
