import firebase from "firebase";
const firebaseConfig = {
    apiKey: "AIzaSyCYhNGwlbbWCknWEYHfbZeFK-wV0LG2sGg",
    authDomain: "nextpak-platform.firebaseapp.com",
    projectId: "nextpak-platform",
    storageBucket: "nextpak-platform.appspot.com",
    messagingSenderId: "826224162928",
    appId: "1:826224162928:web:b8e31f235b31ae4dbe5cb5",
    measurementId: "G-60H1BG4TRM"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;