import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import Navbar from "../components/Navbar";
import SeparatorWrap from "../components/SeparatorWrap";

const Main = (props) => {
    return (
        <>
            {/* <header>Main Header</header> */}
            <Navbar />
            <HeroSection />
            <SeparatorWrap />
            {props.children}
            <Footer />
            {/* <footer>Main Footer</footer> */}
        </>
    );
};

export default Main;
