import React from "react";
import { Col, Container, Row } from "reactstrap";
import Logo from "../assets/img/logo.webp";
// import img from "../assets/img/aestudio-logo-light.svg";
import { FaInstagramSquare, FaLinkedin, FaFacebook } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { navLinks } from "../utils/Content";
const Footer = () => {
    const history = useHistory();
    const location = useLocation();

    return (
        <div className="footer py-3 py-md-5">
            <Container>
                <Row>
                    <Col md="4">
                            <picture>
                                <source type="image/webp" srcset={Logo} />
                                <img src={Logo} alt="brand image"/>
                            </picture>
                        <p>
                            "NextPak is a leading software development and
                            design agency, catering to other agencies by
                            offering top-notch outsourcing solutions for their
                            development and design needs."{" "}
                        </p>
                    </Col>
                    <Col md="5">
                        <Row>
                            <Col md="6">
                                <h4 className="text-nowrap mb-3">
                                    QUICK LINKS
                                </h4>
                                <ul className="list-unstyled p-0">
                                    {navLinks.map((ele, index) => {
                                        return (
                                            <li key={index}
                                                className={` ${location.pathname ===
                                                    ele.link
                                                    ? "text-site-success"
                                                    : ""
                                                    }`}
                                            >
                                                <a
                                                    className={`text-dark mb-2 cursor-pointer   ${location.pathname ===
                                                        ele.link
                                                        ? "text-site-success "
                                                        : ""
                                                        }`}
                                                    // href="#HowWeWork"
                                                    onClick={() =>
                                                        history.push(ele?.link)
                                                    }
                                                >
                                                    {ele?.title}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Col>
                            {/* project management, resource sharing,product development,manage IT services */}
                            <Col md="6">
                                <h4 className="mb-3">SKILLS</h4>
                                <ul className="list-unstyled p-0">
                                    <li>Project Management</li>
                                    <li>Resource Sharing</li>
                                    <li>Product Development</li>
                                    <li>Manage IT Services</li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>

                    <Col md="3" className="social-media">
                        <h5 className="mb-3">WE'RE ACTIVE ON SOCIAL MEDIA!</h5>
                        <a
                            href="https://www.instagram.com/nextpakagile"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>
                                <FaInstagramSquare />
                            </span>
                        </a>
                        <a
                            href="https://pk.linkedin.com/company/nextpak"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>
                                <FaLinkedin />
                            </span>
                        </a>
                        <a
                            href="https://www.facebook.com/Nextpakagilesolutions"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>
                                <FaFacebook />
                            </span>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
