import React, { useEffect } from "react";
import TeamPlans from "../components/TeamPlans";
import PlanData from "../components/PlanData";

const Plans = () => {

    useEffect(() => {
        const element = document.getElementById("/plans");

        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }, []);

    return (
        <div id="/plans">
            <TeamPlans />
            <PlanData />
        </div>
    );
};

export default Plans;
