import { SET_INDIVIDUALPLANS, SET_TEAMPLANS, SET_TESTIMONIALS, SET_HOW_WE_WORK, SET_SERVICES_LIST, SET_QUESTIONS, SET_OFFERS} from "../types";
import firebase from "../../config/firebase";

//INDIVIDUAL PLANS DATA
export const getAllIndividualPlans = () => async (dispatch) => {
    firebase.firestore().collection("individualPlans").onSnapshot((docs) => {
        const individualPlansData = [];
        docs.forEach((doc) => {
            individualPlansData.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_INDIVIDUALPLANS,
            payload: individualPlansData,
        });
    });
};

//TEAM PLANS DATA
export const getAllTeamPlans = () => async (dispatch) => {
    firebase.firestore().collection("teamPlans").onSnapshot((docs) => {
        const teamPlansData = [];
        docs.forEach((doc) => {
            teamPlansData.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_TEAMPLANS,
            payload: teamPlansData,
        });
    });
};

// TESTIMONIALS
export const getAllTestimonials = () => async (dispatch) => {
    firebase.firestore().collection("testimonials").onSnapshot((docs) => {
        const testimonials = [];
        docs.forEach((doc) => {
            testimonials.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_TESTIMONIALS,
            payload: testimonials,
        });
    });
};

//HOW WE GET THINGS DONE SECTION DATA
export const getHowWeWork = () => async (dispatch) => {
    firebase.firestore().collection("getThingsDone").onSnapshot((docs) => {
        const data = [];
        docs.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_HOW_WE_WORK,
            payload: data,
        });
    });
};

//SERVICES SECTION DATA
export const getServicesList = () => async (dispatch) => {
    firebase.firestore().collection("resourceSkills").onSnapshot((docs) => {
        const data = [];
        docs.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_SERVICES_LIST,
            payload: data,
        });
    });
};

//FAQ SECTION DATA
export const getQuestions = () => async (dispatch) => {
    firebase.firestore().collection("faqs").onSnapshot((docs) => {
        const data = [];
        docs.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_QUESTIONS,
            payload: data,
        });
    });
};

//OFFERS SECTION DATA
export const getOffers = () => async (dispatch) => {
    firebase.firestore().collection("weOffer").onSnapshot((docs) => {
        const data = [];
        docs.forEach((doc) => {
            data.push({
                id: doc.id,
                ...doc.data(),
            });
        });
        dispatch({
            type: SET_OFFERS,
            payload: data,
        });
    });
};