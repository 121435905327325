import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import HelpModal from "./Modals/HelpModal";
import LetsTalkModal from "./Modals/LetsTalkModal";

const HeroSection = () => {
    const [helpOpen, setHelpOpen] = useState(false);
    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);
    const helpToggle = () => setHelpOpen(!helpOpen);

    useEffect(() => {
        const createRipple = (event) => {
            const button = event.currentTarget;

            const circle = document.createElement("span");
            const diameter = Math.max(button.clientWidth, button.clientHeight);
            const radius = diameter / 2;

            circle.style.width = circle.style.height = `${diameter}px`;
            circle.style.left = `${event.clientX - button.getBoundingClientRect().left - radius
                }px`;
            circle.style.top = `${event.clientY - button.getBoundingClientRect().top - radius
                }px`;
            circle.classList.add("ripple");

            const ripple = button.getElementsByClassName("ripple")[0];

            if (ripple) {
                ripple.remove();
            }

            button.appendChild(circle);
        };

        const buttons = document.getElementsByClassName("ripple-button");
        for (const button of buttons) {
            button.addEventListener("click", createRipple);
        }

        // Cleanup event listeners when the component unmounts
        return () => {
            for (const button of buttons) {
                button.removeEventListener("click", createRipple);
            }
        };
    }, []);
    return (
        <>
            <Container
                fluid
                className="hero-section__background-video-container"
                itemScope itemType="http://schema.org/AboutPage"
            >
                <video
                    autoPlay
                    loop
                    muted
                    className="hero-section__background-video"
                    itemProp="video"
                >
                    <source src="https://nextpak.org/nextpak-testimonials/backgroundVideo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="hero-section__overlay"></div>
                <Container className="hero-section ml-lg-5">
                    <Row className="w-100" noGutters>
                        <Col className="col-lg-8 pl-lg-4">
                            <div className="hero-left text-left ">
                                <h1 itemProp="contentUrl">
                                    Elevate Work
                                    <div className="text-nowrap">
                                        with Skilled Allies
                                    </div>
                                </h1>
                                <h3 itemProp="description">
                                    We are a <b>development</b> and{" "}
                                    <b>design studio</b> that works closely with
                                    software development <b>agencies</b> to
                                    create <b>futuristic</b> products through
                                    our skilled <b>resources</b>.How we can{" "}
                                    <span
                                        className="text-site-success cursor-pointer border-bottom border-success"
                                        onClick={() => helpToggle()}
                                    >
                                        help
                                    </span>{" "}
                                    you!
                                </h3>

                                <button
                                    className="ripple-button text-white letButt rounded"
                                    onClick={() => talkToggle()}
                                >
                                    LET'S TALK!
                                    <span className="ripple"></span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <HelpModal helpOpen={helpOpen} helpToggle={helpToggle} />
                <LetsTalkModal talkOpen={talkOpen} talkToggle={talkToggle} />
            </Container>
        </>
    );
};

export default HeroSection;