import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
const HelpModal = ({ helpOpen, helpToggle }) => {
    return (
        <Modal isOpen={helpOpen} toggle={helpToggle} centered size="lg">
            <ModalHeader toggle={helpToggle}>How We Operate</ModalHeader>
            <ModalBody>
                <video
                    autoPlay
                    controls
                >
                    <source src="https://nextpak.org/nextpak-testimonials/NEXTPAK%20RENDER.mp4" type="video/mp4" />
                </video>
            </ModalBody>
        </Modal>
    );
};

export default HelpModal;
