import React from "react";

const SeparatorWrap = () => {
    return (
        <div className="separatorWrap ">
            <svg
                width="100%"
                height="180"
                viewBox="0 0 1400 230"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="jsx-115610118 separator"
            >
                <path
                    d="M-50.2324 225.181L1415 2.8905L1406.24 100.036L0.953725 229.107L-50.2324 225.181Z"
                    fill="url(#paint0_linear_0_1)"
                    fillOpacity="0.4"
                    className="jsx-115610118"
                ></path>
                <path
                    d="M-13.0146 204.79L1416.5 0.283272L1409.92 65.9538L-14.8093 222.7L-13.0146 204.79Z"
                    fill="url(#paint1_linear_0_1)"
                    className="jsx-115610118"
                ></path>
                <defs className="jsx-115610118">
                    <linearGradient
                        id="paint0_linear_0_1"
                        x1="1374.89"
                        y1="84.5555"
                        x2="-50.6312"
                        y2="229.161"
                        gradientUnits="userSpaceOnUse"
                        className="jsx-115610118"
                    >
                        <stop
                            stopColor="#2e8b57"
                            className="jsx-115610118"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="#2e8b57"
                            className="jsx-115610118"
                        ></stop>
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_0_1"
                        x1="1048.66"
                        y1="-362.293"
                        x2="-35.0531"
                        y2="524.033"
                        gradientUnits="userSpaceOnUse"
                        className="jsx-115610118"
                    >
                        <stop
                            stopColor="#2e8b57"
                            className="jsx-115610118"
                        ></stop>
                        <stop
                            offset="1"
                            stopColor="#2e8b57"
                            className="jsx-115610118"
                        ></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default SeparatorWrap;
