import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import CheckMark from "./CheckMark";
import { useDispatch, useSelector } from "react-redux";
import { getHowWeWork } from "../store/actions/getDataActions";

const HowWeWork = () => {

    const { HowWeWork } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHowWeWork())
    }, []);

    return (
        <Container className="How-we-work pt-5" id="/home/how-we-work" itemScope itemType="http://schema.org/AboutPage">
            <h1
                data-aos="fade-right"
                data-aos-duration="600"
                itemProp="headline"
            >
                <span>How</span> we get things done
            </h1>
            <p data-aos="zoom-in" data-aos-duration="600" itemProp="description">
                {HowWeWork[0]?.p1}
            </p>
            <p data-aos="zoom-in" data-aos-duration="600" itemProp="description">
                {HowWeWork[0]?.p2}
            </p>
            <Row itemScope itemType="http://schema.org/ItemList">
                <Col className='overflow-hidden' lg="6" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    {HowWeWork[0]?.list?.map((feature, index) => (
                        <p
                            key={index}
                            data-aos="fade-left"
                            data-aos-delay={`${index * 200}`}
                            data-aos-duration="600"
                            itemProp="name"
                        >
                            <CheckMark className="mr-2" />
                            {feature}
                        </p>
                    ))}
                </Col>
                <Col lg="6">
                    <p data-aos="zoom-in" data-aos-duration="600" itemProp="description">
                        {HowWeWork[0]?.p3}
                    </p>
                    <p data-aos="zoom-in" data-aos-duration="600" itemProp="description">
                        {HowWeWork[0]?.p4}
                    </p>
                </Col>
            </Row>
        </Container>
    );
};

export default HowWeWork;
