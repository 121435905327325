import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import LetsTalkModal from "./Modals/LetsTalkModal";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../store/actions/getDataActions";

const WhatWeOffer = () => {

    const [talkOpen, setTalkOpen] = useState(false);
    const talkToggle = () => setTalkOpen(!talkOpen);

    const { offers } = useSelector((state) => state.getData);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getOffers())
    }, []);

    return (
        <Container id="/home/what-we-offer" className="what-we-offer pb-5" itemScope itemType="http://schema.org/OfferCatalog">
            <h1>
                <span>We</span> Offers
            </h1>
            <Row className="mx-auto" itemScope itemType="http://schema.org/Offer">
                {offers.map((item, index) => (
                    <Col key={index} md={4} className=" mt-md-0 mt-3" itemProp="itemListitemment" itemScope itemType="http://schema.org/ListItem">
                        <Card body
                            data-aos="fade-down"
                            data-aos-delay={`${index * 200}`}
                            data-aos-duration="800"
                            className="text-center"
                        >
                            <picture>
                                <source type="image/webp" srcset={item?.image} />
                                <img src={item?.image} alt="icon" itemProp="image" />
                            </picture>
                            <h2 itemProp="name"> {item?.title}</h2>
                            <p className="fw--400" itemProp="description">{item?.detail}</p>
                            <span className="d-flex text-site-success fs--14 fw--500  ">
                                <h2 className="cursor-pointer" onClick={() => { talkToggle() }}>
                                    let's talk{" "}
                                    <FaLongArrowAltRight className="ml-2" />{" "}
                                </h2>
                            </span>
                        </Card>
                    </Col>
                ))}
            </Row>
            <LetsTalkModal talkOpen={talkOpen} talkToggle={talkToggle} />
        </Container>
    );
};

export default WhatWeOffer;
