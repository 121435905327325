
const TestimonialsSlide = ({ data }) => {
    return (
        <div itemScope itemType="http://schema.org/Review"
        className="d-flex col-12 col-lg-6 p-0 h-auto"
        >
            <div className="card m-1 m-md-4">
                <div>
                    <video
                    itemProp="video"
                    width="100%"
                    height="315"
                    controls
                    muted
                >
                    <source src={data?.video} type="video/mp4" />
                </video>
                </div>

                <div className="d-flex align-items-center my-2" itemScope itemType="http://schema.org/Person">
                    <div className="human">

                        <picture>
                            <source type="image/webp" srcset={data?.image} />
                            <img src={data?.image} alt="avatar" itemProp="image" />
                        </picture>
                    </div>
                    <div className="d-flex flex-column">
                        <h3 className="name" itemProp="name">{data?.name}</h3>
                        <h3 className="role" itemProp="jobTitle">{data?.role}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsSlide;
