import AuthView from "../views/auth/AuthView";
import FAQ from "../views/FAQ";
import Home from "../views/Home";
import Plans from "../views/Plans";

let routes = [
    {
        id:"1",
        path: "/auth",
        component: AuthView,
        layout: "auth",
    },
    {
        id:"2",
        path: "/",
        component: Home,
        layout: "main",
    },
    {
        id:"3",
        path: "/home/:navlink",
        component: Home,
        layout: "main",
    },
    {
        id:"4",
        path: "/plans",
        component: Plans,
        layout: "main",
    },
    {
        id:"5",
        path: "/faq",
        component: FAQ,
        layout: "main",
    },
];
export default routes;
